export const COLES_PAGE = 'coles'
export const LECHUGAS_PAGE = 'lechugas'
export const PRIMAVERA_PAGE = 'primavera'
export const OTROS_PAGE = 'otros'

export const PRODUCTS = [COLES_PAGE, LECHUGAS_PAGE, PRIMAVERA_PAGE, OTROS_PAGE]

export const METODO_PATH = '/metodo'
export const SERVICIOS_PATH = '/servicios'
export const INDEX_PATH = '/'

export const MOBILE_MENU_LINKS = [
  ['nosotros', 'nosotros'],
  ['servicios', 'servicios'],
  ['metodo', 'conoce nuestro método'],
  ['contacto', 'contacto'],
  ['terminos', 'aviso legal'],
  ['politica', 'política de privacidad']
]

export const MOBILE_SIZE = 768
export const TABLET_SIZE = 1366

export const MOBILE_DEVICE = 'MOBILE_DEVICE'
export const DESKTOP_DEVICE = 'DESKTOP_DEVICE'

export const PRIMARY_COLOR = "#00b96f"
export const WHITE_BG_COLOR = "#f5efef"