import React from 'react'
import { Link } from "gatsby";

import './styles.css'

const Footer = () => {
  return (
    <footer className="footer">
      <nav>
        <Link to="/contacto">contacto</Link>
        <Link to="/terminos">aviso legal</Link>
        <Link to="/politica">política de privacidad</Link>
      </nav>
    </footer>
  )
}

export default Footer