import React, { useState, useEffect } from "react"
import { Link } from "gatsby";
import { RichText } from 'prismic-reactjs'

import { METODO_PATH, INDEX_PATH } from "../../constants"

import Logo from "../Logo"
import HeaderNosotros from "../HeaderNosotros"
import HeaderServicios from "../HeaderServicios"

import './styles.css'

const HEADER_NOSOTROS = 'header--nosotros'
const HEADER_SERVICIOS = 'header--servicios'

const getAnimationClass = (isVisible) => {
  if (isVisible) return 'header__animation header__animation--visible'
  return 'header__animation'
}

const LogoUI = ({ pathname }) => {
  if (pathname === INDEX_PATH) return null
  return <Logo isWhite={pathname?.includes(METODO_PATH)} />
}

const NosotrosText = ({ pathname, header, isVisible, data }) => {
  if (pathname !== INDEX_PATH || header !== HEADER_SERVICIOS) return null

  return (
    <Link to="/servicios" className={`header__right-section__text ${getAnimationClass(isVisible)}`}>
      <RichText render={data?.servicios_side_text?.raw} />
    </Link>
  )
}

const HeaderLarge = ({ pathname, header, isVisible, data }) => {
  if (pathname === INDEX_PATH && header === HEADER_NOSOTROS) return <HeaderNosotros {...{ isVisible, data }} />
  if (pathname === INDEX_PATH && header === HEADER_SERVICIOS) return <HeaderServicios {...{ isVisible, data }} />
  return null
}

const Overlay = ({ pathname, header, onMouseOver }) => {
  if (!header || pathname === METODO_PATH) return null
  return <div className="header__overlay" {...{ onMouseOver }} />
}

const getClassName = (pathname, header) => {
  if (pathname === INDEX_PATH && header) return `header header--home ${header}`
  if (pathname === INDEX_PATH) return `header header--home`
  if (pathname?.includes(METODO_PATH)) return 'header header--metodo'
  return 'header'
}

const getMenuItemClass = (pathname, name) => {
  if (pathname?.includes(name)) return 'header__menu__item header__menu__item--active'
  return 'header__menu__item'
}

const Header = ({ pathname, data }) => {
  const [header, setHeader] = useState(null)
  const [isVisible, setIsVisible] = useState(false)

  const handleMenuHover = (header) => () => {
    if (!header) return
    setHeader(header)
  }

  const handleOverlayHover = () => {
    setHeader(null)
  }

  useEffect(() => {
    if (!header) {
      setIsVisible(false)
      return
    }

    let showItems = setTimeout(() => setIsVisible(true), 200);

    return () => clearTimeout(showItems)
  }, [header])

  return (
    <>
      <header className={getClassName(pathname, header)}>
        <div className="header__logo">
          <p>
            <Link to="/"><LogoUI {...{ pathname }} /></Link>
          </p>
        </div>
        <HeaderLarge {...{ pathname }} {...{ header }} {...{ isVisible }} {...{ data }} />
        <div className="header__right-section">
          <nav className="header__menu">
            <Link onMouseOver={handleMenuHover(HEADER_NOSOTROS)} className={getMenuItemClass(pathname, '/nosotros')} to="/nosotros">nosotros</Link>
            <Link onMouseOver={handleMenuHover(HEADER_SERVICIOS)} className={getMenuItemClass(pathname, '/servicios')} to="/servicios">servicios</Link>
          </nav>
          <NosotrosText {...{ pathname }} {...{ header }} {...{ isVisible }} {...{ data }} />
        </div>
        <span className="header__background"></span>
      </header>
      <Overlay {...{ header }} {...{ pathname }} onMouseOver={handleOverlayHover} />
    </>
  )
}

export default Header
