import React, { useState, useEffect } from 'react'
import { Link } from "gatsby";
import { RichText } from 'prismic-reactjs'

import { INDEX_PATH, METODO_PATH } from '../../constants'

import ubicacionImg from '../../images/ubicacion.png'
import bioclimaticaImg from '../../images/bioclimatica.png'
import atencionImg from '../../images/atencion.png'

import Logo from '../Logo'

import './styles.css'

const LogoUI = ({ pathname }) => {
  if (pathname === INDEX_PATH) return <Logo />
  return null
}

const HomeText = ({ pathname }) => {
  if (pathname === INDEX_PATH) return <span className="bottom-menu__prepend">conoce nuestro </span>
  return null
}

const Overlay = ({ pathname, isLarge, onMouseOver }) => {
  if (pathname !== INDEX_PATH || !isLarge) return null
  return <div className="bottom-menu__overlay" {...{ onMouseOver }}></div>
}

const Text = ({ render }) => {
  if (!render) return ''
  return <RichText {...{ render }} />
}

const BottomMenu = ({ pathname, data }) => {
  let [isLarge, setIsLarge] = useState(false)
  let [isVisible, setIsVisible] = useState(false)

  const handleMenuHover = () => {
    if (pathname !== INDEX_PATH || isLarge) return
    setIsLarge(true)
  }

  const handleOverlayHover = () => {
    setIsLarge(false)
  }

  useEffect(() => {
    if (!isLarge) {
      setIsVisible(false)
      return
    }

    let showItems = setTimeout(() => setIsVisible(true), 200);

    return () => clearTimeout(showItems)
  }, [isLarge])

  const getClassName = () => {
    if (pathname?.includes(METODO_PATH)) return 'bottom-menu bottom-menu--metodo'
    if (pathname === INDEX_PATH && isLarge) return 'bottom-menu bottom-menu--home bottom-menu--large'
    if (pathname === INDEX_PATH) return 'bottom-menu bottom-menu--home'
    return 'bottom-menu'
  }

  const getAnimationClass = () => {
    if (isVisible) return 'bottom-menu__animation bottom-menu__animation--visible'
    return 'bottom-menu__animation'
  }

  return (
    <>
      <Overlay {...{ pathname, isLarge }} onMouseOver={handleOverlayHover} />
      <nav className={getClassName()}>
        <div className="bottom-menu__left">
          <LogoUI {...{ pathname }} />
          <Link to="/metodo" className={`bottom-menu__text ${getAnimationClass()}`}>
            <Text render={data?.metodos_texto?.raw} />
          </Link>
        </div>
        <Link to="/metodo" className={`bottom-menu__center ${getAnimationClass()}`}>
          <ul className="bottom-menu__variedades">
            <li>
              <div className="bottom-menu__variedad-image">
                <img src={ubicacionImg} alt="Ubicación" />
              </div>
              <span className="bottom-menu__variedad-title">
                <span className="bottom-menu__variedad-title__number">1. </span><span className="bottom-menu__variedad-title__text">{data?.metodos_variedad_a?.text}</span>
              </span>
            </li>
            <li>
              <div className="bottom-menu__variedad-image">
                <img src={bioclimaticaImg} alt="Bioclimática" />
              </div>
              <span className="bottom-menu__variedad-title">
                <span className="bottom-menu__variedad-title__number">2. </span><span className="bottom-menu__variedad-title__text">{data?.metodos_variedad_b?.text}</span>
              </span>
            </li>
            <li>
              <div className="bottom-menu__variedad-image">
                <img src={atencionImg} alt="Atención" />
              </div>
              <span className="bottom-menu__variedad-title">
                <span className="bottom-menu__variedad-title__number">3. </span><span className="bottom-menu__variedad-title__text">{data?.metodos_variedad_c?.text}</span>
              </span>
            </li>
          </ul>
        </Link>
        <div className="bottom-menu__right" onMouseOver={handleMenuHover}>
          <Link to="/metodo">
            <HomeText {...{ pathname }} />método
          </Link>
        </div>
      </nav>
    </>
  )
}

export default BottomMenu