import React, { useState } from 'react'
import { Link } from "gatsby"

import Logo from "../Logo"
import { METODO_PATH, INDEX_PATH, MOBILE_MENU_LINKS } from '../../constants'

import './styles.css'

const LogoUI = () => <Link to="/" className="header-mobile__logo"><Logo isWhite /></Link>

const ButtonUI = ({ open, onClick }) => {
  if (open) return <button className="header-mobile__close" {...{ onClick }} />
  return <button className="header-mobile__button" {...{ onClick }}>menu</button>
}

const Menu = ({ open, setOpen, pathname }) => {
  if (!open) return null

  const getClassName = (pathname, link) => {
    if (pathname.includes(link)) return 'header-mobile__menu__item header-mobile__menu__item--active'
    return 'header-mobile__menu__item'
  }

  return (
    <nav className="header-mobile__menu">
      {MOBILE_MENU_LINKS.map(item => <Link key={`mobile-menu-${item[0]}`} to={`/${item[0]}`} className={getClassName(pathname, item[0])}>{item[1]}</Link>)}
    </nav>
  )
}

const getMainClassName = (pathname) => {
  if (pathname === INDEX_PATH) return `header-mobile header-mobile--home`
  if (pathname?.includes(METODO_PATH)) return 'header-mobile header-mobile--metodo'
  return 'header-mobile'
}

const getClassName = (pathname, open) => {
  const mainClassName = getMainClassName(pathname)

  if (!open) return mainClassName
  return `${mainClassName} header-mobile--open`
}

const getTopClassName = (pathname, open) => {
  if (pathname === METODO_PATH && !open) return 'header-mobile__top header-mobile__top--border'
  return 'header-mobile__top'
}

const HeaderMobile = ({ pathname }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    event.preventDefault()
    setOpen(!open)
  }

  return (
    <header className={getClassName(pathname, open)}>
      <div className={getTopClassName(pathname, open)}>
        <LogoUI />
        <ButtonUI {...{ open }} onClick={handleClick} />
      </div>
      <Menu {...{ pathname }} {...{ open }} />
    </header>
  )
}

export default HeaderMobile