export const debounce = (func, wait, immediate = false) => {
  let timeout
  return (...args) => {
      const later = () => {
          timeout = null // added this to set same behaviour as ES5
          if (!immediate) func(...args) // this is called conditionally, just like in the ES5 version
      }
      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func(...args)
  }
}

export const getAnimation = (delay, animation) => ({
  'data-sal': animation || 'slide-up',
  'data-sal-duration': 1000,
  'data-sal-easing': 'ease-out',
  'data-sal-delay': delay
})

export const getScrollParent = (node) => {
  if (node == null) return null

  if (node.scrollHeight > node.clientHeight) return node
  
  return getScrollParent(node.parentNode)
}