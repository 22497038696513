import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { debounce } from "../../utils"
import { METODO_PATH, INDEX_PATH, SERVICIOS_PATH, MOBILE_SIZE, MOBILE_DEVICE, DESKTOP_DEVICE } from "../../constants"

import Header from "../Header"
import HeaderMobile from "../HeaderMobile"
import BottomMenu from "../BottomMenu"
import Footer from "../Footer"

import './styles.css'

const getClassName = (pathname) => {
  if (pathname === INDEX_PATH) return 'layout layout--home'
  if (pathname?.includes(METODO_PATH)) return 'layout layout--metodo'
  if (pathname?.includes(SERVICIOS_PATH)) return 'layout layout--servicios'
  return 'layout'
}

const HeaderUI = ({ pathname, device, data }) => {
  if (device === MOBILE_DEVICE) return <HeaderMobile {...{ pathname }} />
  return <Header {...{ pathname, data }} />
}

const BottomUI = ({ pathname, device, data }) => {
  if (device === MOBILE_DEVICE) return null
  return <BottomMenu {...{ pathname, data }} />
}

const FooterUI = ({ device }) => {
  if (device === MOBILE_DEVICE) return null
  return <Footer />
}

const Layout = ({ data, pathname, children, isHome }) => {
  const [device, setDevice] = useState(DESKTOP_DEVICE)

  useEffect(() => {
    if (window === 'undefined') return

    const handleSetDevice = () => {
      if (window?.innerWidth > MOBILE_SIZE) {
        setDevice(DESKTOP_DEVICE)
        return
      }
  
      setDevice(MOBILE_DEVICE)
    }
  
    const handleResize = debounce(handleSetDevice, 500)

    handleSetDevice()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    } 
  }, [pathname])

  return (
    <>
      <div className={getClassName(pathname)}>
        <HeaderUI {...{ pathname, device, data }} />
        <main>{children}</main>
        <BottomUI {...{ pathname, device, data }} />
      </div>
      <FooterUI {...{ device }} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
