import React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { PRODUCTS } from '../../constants'

import serviciosColesImg from '../../images/servicios-coles-icon.png' 
import serviciosLechugasImg from '../../images/servicios-lechugas-icon.png' 
import serviciosPrimaveraImg from '../../images/servicios-primavera-icon.png' 
import serviciosOtrosImg from '../../images/servicios-otros-icon.png'

import './styles.css'

const IMAGES = {
  'coles': serviciosColesImg,
  'lechugas': serviciosLechugasImg,
  'primavera': serviciosPrimaveraImg,
  'otros': serviciosOtrosImg,
}

const Item = ({ product }) => (
  <li>
    <img src={IMAGES[product]} alt={product} />
    <p>{product}</p>
  </li>
)

const HeaderServicios = ({ data, isVisible }) => {
  const getAnimationClass = () => {
    if (isVisible) return 'header__animation header__animation--visible'
    return 'header__animation'
  }

  return (
    <Link to="/servicios" className={`header__servicios ${getAnimationClass()}`}>
      <div className="header__servicios__intro">
        <p className="header__servicios__intro__lead">{data?.servicios_title?.text}</p>
        <p className="header__servicios__intro__lead">{data?.servicios_subtitle_a?.text}</p>
        <RichText render={data?.servicios_description?.raw} />
        <p className="header__servicios__intro__lead">{data?.servicios_subtitle_b?.text}</p>
      </div>
      <ul className="header__servicios__variedades">
        {PRODUCTS.map(product => <Item key={`servicios-item-${product}`} {...{ product }} />)}
      </ul>
    </Link>
  )
}

export default HeaderServicios