import React from 'react'

import './styles.css'

const Logo = ({ isWhite }) => {
  const className = isWhite ? 'plantcanal-logo plantcanal-logo--white' : 'plantcanal-logo'

  return (
    <svg id="plantcanal-logo" className={className} width="208.12" height="31.9" data-name="plantcanal-logo"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.12 31.9">
      <title>Plantcanal</title>
      <path className="cls-1" d="M64.15,9.26A5.91,5.91,0,0,0,58.7,12.1V9.58H55.32V31.82H58.7V24.29a5.91,5.91,0,0,0,5.45,2.83c4.06,0,7.53-3.34,7.53-8.95S68.21,9.26,64.15,9.26Zm-.78,15.09c-2.6,0-4.81-1.94-4.81-6.18S60.77,12,63.37,12c2.82,0,4.71,2.23,4.71,6.14S66.2,24.36,63.37,24.36Z"/>
      <rect className="cls-1" x="75.04" y="5.19" width="3.38" height="21.52"/>
      <path className="cls-1" d="M97.12,16.43c0-5.13-2.53-7.26-7-7.26-4,0-7.08,2.16-7.34,5.83h3.41c.13-2.13,1.46-3.28,3.8-3.28,2.14,0,3.77,1,3.77,4v.35c-6.75.67-11.79,1.82-11.79,6,0,3.12,2.7,5,6.23,5q4.24,0,5.84-2.67a18.66,18.66,0,0,0,.29,2.26h3.25a31.09,31.09,0,0,1-.49-6.18C97.05,19.8,97.12,16.84,97.12,16.43ZM93.87,19.8c0,3.41-2.21,4.78-5,4.78-2.08,0-3.31-1-3.31-2.55,0-2.2,2.86-3,8.28-3.47Z"/>
      <path className="cls-1" d="M110.15,9.26a5.92,5.92,0,0,0-5.49,2.8V9.58h-3.38V26.71h3.38v-9.2c0-3.44,1.4-5.48,4.35-5.48,3.21,0,3.6,2.32,3.6,4.78v9.9H116V15.92C116,11.94,114.37,9.26,110.15,9.26Z"/>
      <path className="cls-1" d="M124.28,5.19H120.9V9.58h-2.66V12.1h2.66V22.32c0,3.54.78,4.39,4.32,4.39h2.47V24.07h-1.43c-1.69,0-2-.45-2-2.2V12.1h3.44V9.58h-3.44Z"/>
      <path className="cls-1" d="M138.08,24.29c-2.43,0-4.67-1.91-4.67-6.14s2.24-6,4.67-6a4.21,4.21,0,0,1,4.35,3.28h3.38a7.51,7.51,0,0,0-7.56-6.11c-5.2,0-8.44,3.85-8.44,8.92S133,27.13,138,27.13A7.92,7.92,0,0,0,146,21h-3.41A4.39,4.39,0,0,1,138.08,24.29Z"/>
      <path className="cls-1" d="M163.32,16.43c0-5.13-2.53-7.26-7-7.26-4,0-7.08,2.16-7.34,5.83h3.41c.13-2.13,1.46-3.28,3.8-3.28,2.14,0,3.77,1,3.77,4v.35c-6.75.67-11.79,1.82-11.79,6,0,3.12,2.7,5,6.23,5q4.24,0,5.84-2.67a18.69,18.69,0,0,0,.29,2.26h3.25a31.1,31.1,0,0,1-.49-6.18C163.25,19.8,163.32,16.84,163.32,16.43Zm-3.25,3.38c0,3.41-2.21,4.78-5,4.78-2.08,0-3.31-1-3.31-2.55,0-2.2,2.86-3,8.28-3.47Z"/>
      <path className="cls-1" d="M176.35,9.26a5.92,5.92,0,0,0-5.49,2.8V9.58h-3.38V26.71h3.38v-9.2c0-3.44,1.4-5.48,4.35-5.48,3.21,0,3.6,2.32,3.6,4.78v9.9h3.38V15.92C182.19,11.94,180.57,9.26,176.35,9.26Z"/>
      <path className="cls-1" d="M200.58,16.43c0-5.13-2.53-7.26-7-7.26-4,0-7.08,2.16-7.34,5.83h3.41c.13-2.13,1.46-3.28,3.8-3.28,2.14,0,3.77,1,3.77,4v.35c-6.75.67-11.79,1.82-11.79,6,0,3.12,2.7,5,6.23,5q4.24,0,5.84-2.67a18.5,18.5,0,0,0,.29,2.26H201a31.09,31.09,0,0,1-.49-6.18C200.51,19.8,200.58,16.84,200.58,16.43Zm-3.25,3.38c0,3.41-2.21,4.78-5,4.78-2.08,0-3.31-1-3.31-2.55,0-2.2,2.86-3,8.28-3.47Z"/>
      <rect className="cls-1" x="204.74" y="5.19" width="3.38" height="21.52"/>
      <path className="cls-1" d="M24.86,10.84a18.67,18.67,0,0,0-5.29-9.68L18.47.08l-1.1,1.08a18.73,18.73,0,0,0-5.3,9.66A18.58,18.58,0,0,0,1.56,7.59H0V9.12a18.29,18.29,0,0,0,16.79,18v4.65h3.38V27.17a18.29,18.29,0,0,0,16.78-18V7.59H35.39A18.57,18.57,0,0,0,24.86,10.84ZM18.47,4.51A14.91,14.91,0,0,1,22.07,14,14.59,14.59,0,0,1,19.6,21.9a17.87,17.87,0,0,0-4.71-8.7A15,15,0,0,1,18.47,4.51ZM3.2,10.73a15.36,15.36,0,0,1,9.22,4.33,14.84,14.84,0,0,1,4.41,9A15.25,15.25,0,0,1,3.2,10.73Zm18.89,13a17.75,17.75,0,0,0,3.06-9.26,15.35,15.35,0,0,1,8.59-3.77A15.2,15.2,0,0,1,22.09,23.76Z"/>
    </svg>
  )
}

export default Logo