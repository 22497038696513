import React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import './styles.css'

const HeaderNosotros = ({ data, isVisible }) => {
  const getAnimationClass = () => {
    if (isVisible) return 'header__animation header__animation--visible'
    return 'header__animation'
  }

  return (
    <Link to="/nosotros" className={`header__nosotros ${getAnimationClass()}`}>
      <div className="header__nosotros__image">
        <img src={data?.nosotros_image?.url} alt={data?.nosotros_image?.alt} />
      </div>
      <div className="header__nosotros__intro">
        <RichText render={data?.nosotros_texto?.raw} />
      </div>
      <div className="header__nosotros__description">
        <RichText render={data?.nosotros_values?.raw} />
      </div>
    </Link>
  )
}

export default HeaderNosotros